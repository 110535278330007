import React, {Component} from "react";
import {connect} from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {FilterColor_1, FilterColor_2, InfoCard,} from "../../../../components";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  render() {
    const { viewPointHeight, isLoading } = this.state;
    const { isMoreInfo, onClick } = this.props;

    return (
      <>
        {/* About Us */}
        <div className={"mim__about"}>
          {/* Top Wrapper */}
          <div className={"container"}>
            <div className={"mima__top-wrapper"}>
              <div className={"mima__tw"}>
                <div className={"mima__tw-welcome d-flex flex-row"}>
                  <div className={"mima__tww-title"}>Welcome to</div>
                  <div className={"d-flex flex-row"}>
                    <span className={"mima__tww-creditcard"}>creditcardoffers</span>
                    <span className={"mima__tww-lk"}>.lk</span>
                  </div>
                </div>
                <div className={"mima__tw-content"}>
                  CreditCardOffers.lk is a leading platform developed under
                  Difynbest Solutions (Pvt) Ltd, dedicated to helping you discover the best
                  credit card offers in one convenient place. Our mission is to
                  simplify the process of finding and comparing credit card
                  deals so you can make smart financial decisions
                  effortlessly.
                </div>
              </div>
              {/* who we are wrapper */}
              <div className={"mima__tw-who"}>
                <InfoCard
                    imgUrl={"../../../assets/img/Who_WeAre.svg"}
                    type={"content-right"}
                    title={"Who We Are"}
                    content={
                      "creditCardOffers.lk is powered by a small but exceptionally talented team of creative designers and skilled engineers at Difynbest Solutions (Pvt) Ltd. Our dedicated team works tirelessly to ensure our platform delivers accurate and up-to-date information on the latest credit card offers."
                    }
                    // imgUrl={require("../../../../assets/img/solution_steps/Group 1787.png")}
                />
              </div>
            </div>
          </div>
          {/* why-choose-wrapper */}
          <div className={"mima__why"}>
            <div className={"image-wrp-2"}>
              <img src={require("../../../../assets/img/Rectangle 4582.png")}/>
            </div>
            <div className={"filter-wrp-2"}>
              <FilterColor_1/>
            </div>
            <div className="row justify-content-md-center">
              <div className={'col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl6'}>
                <div className={"mima__w-title"}>Why Choose Us</div>
                <div className={"mima__w-body d-flex flex-column"}>
                  <div className={"mima__wb-content d-flex flex-row"}>
                    <div className={"mima__w-compihensive"}>
                      <div className={"mima__wcc-title"}>
                        Comprehensive Offerings
                      </div>
                      <div className={"mima__wcc-content"}>
                        We provide a wide range of credit card offers in one place,
                        making it easy to find the best deal for your needs.
                      </div>
                    </div>
                    <div className={"mima__wc-user"}>
                      <div className={"mima__wcu-title"}>
                        User-Friendly Experience
                      </div>
                      <div className={"mima__wcu-content"}>
                        Our platform is designed to be intuitive and easy to
                        navigate, ensuring a hassle-free experience for our users.
                      </div>
                    </div>
                  </div>
                  <div className={"mima__wc-expert"}>
                    <div className={"mima__wce-title"}>Expert Team</div>
                    <div className={"mima__wce-content"}>
                      Our team of talented professionals is committed to delivering
                      top-notch service and continually improving our platform.
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className={"image-wrp-3"}>
              <img src={require("../../../../assets/img/Group 5 (1).png")}/>
            </div>
            <div className={"filter-wrp-3"}>
              <FilterColor_2/>
            </div>
          </div>

          {/* get in touch */}
          <div className={"mima__get-touch"}>
            <div className={"mima__gt__title"}>Get in Touch</div>
            <div className={"mima__gt__content"}>
              Ready to discover the best credit card offers? Visit
              CreditCardOffers.lk or contact us at
              <span className={"mima__gtc__email"}>info@difynbest.com</span>
              for more information. Follow us on social media for the latest
              updates and exclusive offers.
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
