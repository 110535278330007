import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  DetailsCard,
  InfoCard,
  Input,
} from "../../../../components";
import {
  RiArrowRightLine,
  RiFacebookFill,
  RiInstagramLine,
  RiTiktokFill,
  RiYoutubeLine,
} from "react-icons/ri";
import { GetIcon } from "../../../../config/icon";
import { GetMessageText, StringRegex, validateNumber } from "../../../../utils";
import { Select, Space } from "antd";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      wizardNo: 1,
      userId: null,
      tags: [],
      tag: null,
      otp: null,
      token: null,
      attempt: 0,
      termsAndCondition: true,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      isFoundUser: false,
      formHeader: "Sign up",
      userType: 1,
      isLogo: true,
      timer: "00:00",
      userData: null,
      registeredType: 1,
      wizardStep: 1,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { viewPointHeight, isLoading, dataForm } = this.state;
    const { isMoreInfo, onClick } = this.props;

    return (
      <>
        {/*Cookie Policy*/}
        <div className={"mim__cookie"}>
          <div className={"mimc__top-wrapper"}>
            <div className={"mimc__tw-title"}>{"Last Update : [Date]"}</div>
            <div className={"mimc__tw-content flex-wrap"}>
              <span className={"mimc__mail"}>creditcardoffers.lk</span>
              {
                "uses cookies and similar technologies to improve your experience on our website"
              }
              <span className={"mimc__web"}>https://creditcardoffers.lk</span>
              {
                "This Cookie Policy explains what cookies are, how we use them, and your choices regarding cookies."
              }
            </div>
          </div>
          <DetailsCard
            no={"1."}
            title={" What Are Cookies?"}
            content={
              "Cookies are small text files that are placed on your device (computer, smartphone, tablet) when you visit a website. They are widely used to make websites work more efficiently, as well as to provide information to the site owners."
            }
          />
          <DetailsCard
            no={"2."}
            title={" How We Use Cookies"}
            content={
              "We use cookies to improve your experience on our Site by remembering your preferences, personalizing content and ads, providing social media features, and analyzing our traffic. We may also share information about your use of our Site with our social media, advertising, and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of"
            }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
