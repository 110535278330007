import React, {Component} from "react";
import {connect} from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {ButtonIcon, FilterColor_1} from "../../../components";
import {GetIcon} from "../../../config/icon";
import * as actionTypes from "../../../store/actions/types";
import Scrollbars from "react-custom-scrollbars";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Careers from "./Careers";
import Footer from "../../Footer";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicy from "./CookiePolicy";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  render() {
    const { viewPointHeight, isLoading, viewPointWidth } = this.state;
    const { isMoreInfo, selectMoreInfo } = this.props;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isMoreInfo ? "show-me" : "hide-me"
        }`}
      >
        <div
          className={
            "coo_modal-body-content d-flex flex-column animate__animated animate__fadeInUp"
          }
          style={{
            top: "0",
            borderRadius: "0",
            height: "100%",
            marginTop: "0",
            //padding: "42px 150px 0 150px",
          }}
        >
          <div className={"image-wrp-1"}>
            <img src={require("../../../assets/img/Group 5_1.png")} />
          </div>
          <div className={"mobile-image-wrp-1"}>
            <img src={require("../../../assets/img/Group 5_mobile.png")} />
          </div>
          <div className={"filter-wrp-1"}>
            <FilterColor_1 />
          </div>
          <div className={"mim__header"}>
            <div className={'container'}>
              <div className={"d-flex flex-row"}>
                <ButtonIcon
                    type={"back"}
                    onClick={() => this.props.onClickCloseMoreInfo()}
                    size={"lg-x"}
                    buttonIcon={GetIcon("leftArrow")}
                />

                <div className={"mimh__title"}>{selectMoreInfo} </div>
              </div>
            </div>
          </div>
          <Scrollbars
            style={{
              height:
                viewPointWidth <= 650
                  ? viewPointHeight - 85
                  : viewPointHeight - 110,
            }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className={"mim__content"}>
              <>
                {selectMoreInfo === "About Us" && <AboutUs />}
                {selectMoreInfo === "Careers" && <Careers />}
                {selectMoreInfo === "Contact Us" && <ContactUs />}
                {selectMoreInfo === "Terms and Conditions" && (
                  <TermsAndConditions />
                )}
                {selectMoreInfo === "Privacy Policy" && <PrivacyPolicy />}
                {selectMoreInfo === "Cookie Policy" && <CookiePolicy />}
              </>
            </div>
            <div className={"mim__footer"}>
              <Footer />
            </div>
          </Scrollbars>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMoreInfo: state.moreInfoReducer.isMoreInfo,
    selectMoreInfo: state.moreInfoReducer.selectMoreInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseMoreInfo: () =>
      dispatch({ type: actionTypes.CLOSE_MORE_INFO_MODAL, status: false }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
