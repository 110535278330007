import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  CareerCard,
  InfoCard,
} from "../../../../components";
import { RiArrowRightLine } from "react-icons/ri";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  render() {
    const { viewPointHeight, isLoading } = this.state;
    const { isMoreInfo, onClick } = this.props;

    return (
      <>
        {/* Careers */}

        <div className={"mim__contact"}>
          <div
            className={
              "mimca__top-wrapper d-flex flex-column justify-content-center align-items-center container"
            }
          >
            <div className={"mimca__tw-header"}>
              We are looking for a talent people
            </div>
            <div className={"mimca__tw-content "}>
              We are seeking talented individuals to join our dynamic team. If
              you are innovative, passionate, and ready to make a difference, we
              want to hear from you!
            </div>
          </div>

          <CareerCard
            title="Frontend/Backend Developer (Paid Internship)"
            date="1 days ago"
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
