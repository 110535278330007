import React from 'react';

import {ReactNotifications} from 'react-notifications-component'
import "../src/assets/stylesheets/vendor/bootstrap.min.css";
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css'

//import {ConnectedRouter} from 'connected-react-router';
import {BrowserRouter as Router} from 'react-router-dom';
//import ConsoleComponent from "./containers/console";
//import PublicRoute from './routes/PublicRoute';
//import PrivateRoute from './routes/PrivateRoute';
import {Helmet} from 'react-helmet'; // Import react-helmet
import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux'
import thunk from 'redux-thunk';

import AppRouter from './routes/AppRouter';
import rootReducer from './store/reducers/index';


const createHistory = require('history');


//export const history = createHistory();
// API key of the google map

// function logger({getState}) {
//     return next => action => {
//         console.log('will dispatch', action);
//         // Call the next dispatch method in the middleware chain.
//         const returnValue = next(action);
//         console.log('state after dispatch', getState());
//         // This will likely be the action itself, unless
//         // a middleware further in chain changed it.
//         return returnValue
//     }
// }

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

//const store = createStore(rootReducer, ['Use Redux'], applyMiddleware(logger));
const store = createStore(
    rootReducer,
    applyMiddleware(thunk),
);
//inject store to api

function App() {
    return (
        <>
            {/*<div className={'preloader'}/>*/}
            <Helmet>
                <title>Creditcardoffers.lk - A Right Choice changes everything.</title>
                <meta name="description"
                      content="Save Money in Sri Lanka: Latest Credit Card Offers, Deals & Promotions | CreditCardOffers.lk"/>
                <meta name="keywords"
                      content="Credit card offers Sri Lanka, Latest credit card deals, Sri Lanka credit card promotions,
                      Best credit card offers, Credit card discounts, Sri Lanka cashback offers, Bank promotions, Hot deals Sri Lanka, Credit card rewards, Exclusive offers Sri Lanka"/>
                <link rel="canonical" href="https://www.creditcardoffers.lk"/>
            </Helmet>
            <ReactNotifications/>
            <Provider store={store}>
                <Router history={createHistory}>
                    <AppRouter/>
                </Router>
            </Provider>

        </>
    );
}

export default  App;
