import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  DetailsCard,
  InfoCard,
  Input,
} from "../../../../components";
import {
  RiArrowRightLine,
  RiFacebookFill,
  RiInstagramLine,
  RiTiktokFill,
  RiYoutubeLine,
} from "react-icons/ri";
import { GetIcon } from "../../../../config/icon";
import { GetMessageText, StringRegex, validateNumber } from "../../../../utils";
import { Select, Space } from "antd";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      wizardNo: 1,
      userId: null,
      tags: [],
      tag: null,
      otp: null,
      token: null,
      attempt: 0,
      termsAndCondition: true,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      isFoundUser: false,
      formHeader: "Sign up",
      userType: 1,
      isLogo: true,
      timer: "00:00",
      userData: null,
      registeredType: 1,
      wizardStep: 1,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { viewPointHeight, isLoading, dataForm } = this.state;
    const { isMoreInfo, onClick } = this.props;

    return (
      <>
        {/* Terms and Conditions */}
        <div className={"mim__terms"}>
          <DetailsCard
            no={"1."}
            title={" Introduction"}
            content={
              "Welcome to creditcardoofers.lk (“we”, “our”, “us”). These Terms and Conditions (“Terms”) govern your use of our website and services. By accessing or using creditcardoofers.lk, you agree to comply with and be bound by bound by these Terms. "
            }
          />
          <DetailsCard
            no={"2."}
            title={" Acceptance of Terms"}
            content={
              "By using our website, you accept these Terms in full. If you disagree with any part of these Terms, you must not use our website."
            }
          />
          <DetailsCard
            no={"3."}
            title={" Changes to Terms"}
            content={
              " We reserve the right to modify these Terms at any time. We will notify you of any changes by updating the “Last Updated” date at the top of this page. Your continued use of the website after any changes signifies your acceptance of the new Terms."
            }
          />
          <DetailsCard
            no={"4."}
            title={" User Responsibilities"}
            content={
              "You agree to use our website for lawful purposes only. You must not use our website for any activities that violate any laws, infringe on anyone’s rights, or are otherwise objectionable. "
            }
          />
          <DetailsCard
            no={"5."}
            title={" Account Information"}
            content={
              "To access certain features of our website, you must create an account using your mobile number. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You must notify us immediately of any unauthorized use of your account."
            }
          />
          <DetailsCard
            no={"6."}
            title={" Privacy Policy"}
            content={
              "We value your privacy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information. "
            }
          />
          <DetailsCard
            no={"7."}
            title={" Intellectual Property"}
            content={
              "All content on our website, including text, graphics, logos, and images, is the property of [Your Website Name] or its content suppliers and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content without our express written permission."
            }
          />
          <DetailsCard
            no={"8."}
            title={" User-Generated Content"}
            content={
              "Users may post or pin favorite offers after logging in. You are responsible for the content you submit and must ensure it does not violate any laws or third-party rights. We reserve the right to remove any content that we deem inappropriate or in violation of these Terms."
            }
          />
          <DetailsCard
            no={"9."}
            title={" Offer Information"}
            content={
              "We collect offer information from various bank websites and strive to ensure the accuracy and completeness of this information. However, we do not guarantee the accuracy, completeness, or reliability of any offer information and disclaim any liability for errors or omissions."
            }
          />
          <DetailsCard
            no={"10."}
            title={" Disclaimers"}
            content={
              "Our website and services are provided “as is” without any warranties, express or implied. We do not warrant that our website will be uninterrupted, secure, or error-free."
            }
          />
          <DetailsCard
            no={"11."}
            title={" Limitation of Liability"}
            content={
              "In no event shall creditcardoffers.lk be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website. "
            }
          />
          <DetailsCard
            no={"12."}
            title={" Indemnification"}
            content={
              "You agree to indemnify and hold [Your Website Name] harmless from any claims, damages, or expenses arising out of your use of our website or your violation of these Terms."
            }
          />
          <DetailsCard
            no={"13."}
            title={" Termination"}
            content={
              "We reserve the right to terminate your account and access to our website at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the website or us."
            }
          />
          <DetailsCard
            no={"14."}
            title={" Governing Law"}
            content={
              "These Terms are governed by and construed in accordance with the laws of Colombo Sri, Lanka, without regard to its conflict of law principles. "
            }
          />
          <DetailsCard
            no={"15."}
            title={" Contact Information"}
            content={
              "If you have any questions or concerns about these Terms, please contact us at +94 11 233 233 33."
            }
          />
          <DetailsCard
            no={"16."}
            title={" Miscellaneous"}
            content={
              "If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect. These Terms constitute the entire agreement between you and creditcardoffers.lk regarding your use of our website. Please replace placeholders like “creditcardoffers.lk” and +94 11 233 233 33 with your actual website name and contact details. This draft provides a comprehensive overview of the terms and conditions tailored to your specific needs."
            }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
