import React from "react";
import { Switch } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import ConsoleComponent from "../containers/console";
import SignInComponent from "../containers/signIn";
import SignUpComponent from "../containers/signUp";
import ViewOffer from "../containers/viewOffer";
import VerifyMobile from "../containers/verifyMobile";
import forgetPassword from "../containers/forgetPassword";

const Index = (props) => (
  <Switch>
    <PublicRoute component={SignInComponent} path="/sign-in" />
    <PublicRoute component={SignUpComponent} path="/sign-up" />
    <PublicRoute component={forgetPassword} path="/forget-password" />
    <PublicRoute component={VerifyMobile} path="/verify-mobileNo" />
    <PublicRoute component={ViewOffer} path="/offer-view/:offerId" exact />

    <PublicRoute component={ConsoleComponent} path="/" exact />
    <PublicRoute component={ConsoleComponent} path="/:module" exact />
    <PrivateRoute component={ConsoleComponent} path="/:module/:module" exact />
    <PrivateRoute
      component={ConsoleComponent}
      path="/:module/:module/:module"
      exact
    />
    <PrivateRoute
      component={ConsoleComponent}
      path="/:module/:module/:module/:module"
      exact
    />
  </Switch>
);

export default Index;
