import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import { Button, Input } from "../../../../components";
import {
  RiFacebookFill,
  RiInstagramLine,
  RiTiktokFill,
  RiYoutubeLine,
} from "react-icons/ri";
import { GetIcon } from "../../../../config/icon";
import {
  getInsight,
  GetMessageText,
  StringRegex,
  validateNumber,
} from "../../../../utils";
import { addNewFeedback } from "../../../../service/feedbackApi";
import { MdMail } from "react-icons/md";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      wizardNo: 1,
      userId: null,
      tags: [],
      tag: null,
      otp: null,
      token: null,
      attempt: 0,
      termsAndCondition: true,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      isFoundUser: false,
      formHeader: "Sign up",
      userType: 1,
      isLogo: true,
      timer: "00:00",
      userData: null,
      registeredType: 1,
      wizardStep: 1,
      dataForm: {
        firstName: {
          key: "firstName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "How we call you",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "First Name",
        },
        email: {
          key: "email",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Enter you working email address",
          },
          value: "",
          validation: {
            required: true,
            email: true,
          },
          valid: false,
          touched: false,
          label: "Working email address",
        },
        mobileNo: {
          key: "mobileNo",
          elementType: "phoneNo",
          elementConfig: {
            type: "input",
            placeholder: "Ex : 7xx xxx xxx",
            minLength: "9",
            maxLength: "9",
          },
          value: "",
          validation: {
            required: true,
            maxLength: 9,
            mobileNo: true,
          },
          valid: false,
          touched: false,
          label: "Enter your Mobile No",
        },
        message: {
          key: "message",
          elementType: "textArea",
          elementConfig: {
            type: "text",
            placeholder: "Write message here",
            rows: "4",
            cols: "50",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "Message",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  //==== input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "mobileNo":
        this.setState({ isFoundUser: false });
        updatedFormElement.value = validateNumber(
          updatedFormElement.value,
          event.target.value.toString()
        );
        break;
      case "otpVerification":
        updatedFormElement.value = event.target.value;
        if (event.target.value.length === 6) {
          this.setState({ wizardNo: 4 });
          // alert("event fire...");
        }
        break;
      default:
        updatedFormElement.value = event.target.value;
        break;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  //=== validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.mobileNo) {
      isValid = true;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  validation = (validationValue, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = validationValue;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async updateInputValidationErrors() {
    const { wizardNo, userType, termsAndCondition } = this.state;
    let df = { ...this.state.dataForm };
    let formIsValid = true;
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //========= VALIDATION USER -- OFFER VIEWER
    for (let itemKey of Object.keys(df)) {
      if (itemKey === "firstName") {
        if (df[itemKey].value === "") {
          df[itemKey].touched = true;
          df[itemKey].valid = false;
          formIsValid = false;
        } else {
          if (!StringRegex.test(df[itemKey].value)) {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            df[itemKey].invalidReason = GetMessageText("invalidFirstName");
            formIsValid = false;
          }
        }
      }
      if (itemKey === "mobileNo") {
        if (df[itemKey].value === "") {
          df[itemKey].touched = true;
          df[itemKey].valid = false;
          formIsValid = false;
        }
      }
      if (itemKey === "email") {
        if (df[itemKey].value === "") {
          df[itemKey].touched = true;
          df[itemKey].valid = false;
          formIsValid = false;
        } else {
          if (!re.test(String(df[itemKey].value).toLowerCase())) {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            df[itemKey].invalidReason = GetMessageText("invalidEmail");
            formIsValid = false;
          }
        }
      }
    }

    this.setState({ dataForm: df, formIsValid: formIsValid });
    return formIsValid;
  }

  async clearAll() {
    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      updateForm[key].value = "";
      this.setState({ dataForm: updateForm });
    }
  }

  async saveFeedback() {
    await this.updateInputValidationErrors();

    this.setState({ isLoading: true });
    let df = { ...this.state.dataForm };

    const insight = await getInsight();

    let body = {
      insightId: insight?.insightId,
      firstName: df.firstName.value,
      mobileNo: df.mobileNo.value,
      email: df.email.value,
      message: df.message.value,
    };
    let res = await addNewFeedback(body);
    console.log("addNewFeedback", res);
    if (res && res.status) {
      this.setState({
        isLoading: false,
      });
      this.clearAll();
    } else {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { viewPointHeight, isLoading, dataForm } = this.state;
    const { isMoreInfo, onClick } = this.props;
    const options = [
      {
        value: "Sri Lanka +94",
        label: "Sri Lanka +94",
      },
      {
        value: "India +91",
        label: "India +91",
      },
      {
        value: "England +44",
        label: "England +44",
      },
      {
        value: "Australia +61",
        label: "Australia +61",
      },
    ];
    return (
      <>
        {/* Contact Us */}
        <div className={"mim__contact"}>
          {/*Top wrapper */}
          <div className={"container"}>
            <div
              className={
                "mimc__top-wrapper d-flex flex-column justify-content-center align-items-center"
              }
            >
              <div className={"mimc__tw-header"}>
                Get in Touch with Our Team
              </div>
              <div className={"mimc__tw-content"}>
                Have questions, feedback, business inquiries, or need
                assistance? Our dedicated team is here to provide support.
              </div>
            </div>

            {/* Body wrapper*/}
            <div
              className={"mimc__body d-flex flex-row justify-content-between"}
            >
              <div className={"mimc__b-left"}>
                <div className={"mimc__bl-call"}>
                  <div className={"mimc__bl-title"}>Call use</div>
                  <div className={"mimc__bl-content"}>
                    Call out team monday to friday 8.00am to 5.00 pm
                  </div>
                  <div className={"mimc__bl-contact mt-3 mb-2 d-flex flex-row"}>
                    <div className={"mimc__blc-icon"}>{GetIcon("phone")}</div>
                    <div className={"mimc__blc-no"}>+94 11 233 233 33</div>
                  </div>
                  <div className={"mimc__bl-contact d-flex flex-row"}>
                    <div className={"mimc__blc-icon"}>
                      <MdMail />
                      {/* {GetIcon("email")} */}
                    </div>
                    <div className={"mimc__blc-no"}>
                      info@creditcardoffers.lk
                    </div>
                  </div>
                </div>
                <div className={"mimc__bl-follow"}>
                  <div className={"mimc__bl-title"}>
                    Following use on social media
                  </div>
                  <div className={"mimc__bl-content"}>
                    Call out team monday to friday 8.00am to 5.00 pm
                  </div>
                  <div className={"mimc__bl-media d-flex flex-row"}>
                    <div className={"mimc__blm-logo"}>
                      <RiInstagramLine />
                    </div>
                    <div className={"mimc__blm-logo"}>
                      <RiYoutubeLine />
                    </div>
                    <div className={"mimc__blm-logo"}>
                      <RiTiktokFill />
                    </div>
                    <div className={"mimc__blm-logo"}>
                      <RiFacebookFill />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className={"mimc__b-right"}>
                <div className={"mimc__br-text"}>
                  <Input
                    elementConfig={dataForm.firstName.elementConfig}
                    elementType={dataForm.firstName.elementType}
                    required={dataForm.firstName.validation.required}
                    isinvalid={!dataForm.firstName.valid}
                    isShowPasswordStrengthMeter={false}
                    invalidReason={dataForm.firstName.invalidReason}
                    touched={dataForm.firstName.touched}
                    value={dataForm.firstName.value}
                    isRightIcon={false}
                    rightIcon={GetIcon("eye")}
                    size={"lg"}
                    margin={"m-t-0"}
                    label={dataForm.firstName.label}
                    changed={(event) =>
                      this.inputHandleChange(event, dataForm.firstName.key)
                    }
                  />
                  {/*<Input*/}
                  {/*    elementConfig={dataForm.mobileNo.elementConfig}*/}
                  {/*    elementType={dataForm.mobileNo.elementType}*/}
                  {/*    required={dataForm.mobileNo.validation.required}*/}
                  {/*    maxLength={dataForm.mobileNo.validation.maxLength}*/}
                  {/*    invalidReason={dataForm.mobileNo.invalidReason}*/}
                  {/*    isinvalid={!dataForm.mobileNo.valid}*/}
                  {/*    touched={dataForm.mobileNo.touched}*/}
                  {/*    value={dataForm.mobileNo.value}*/}
                  {/*    size={"lg"}*/}
                  {/*    verificationStatus={dataForm.mobileNo.valid}*/}
                  {/*    showVerification={!!dataForm.mobileNo.value}*/}
                  {/*    margin={"m-t-24"}*/}
                  {/*    label={dataForm.mobileNo.label}*/}
                  {/*    changed={(event) =>*/}
                  {/*        this.inputHandleChange(event, dataForm.mobileNo.key)*/}
                  {/*    }*/}
                  {/*/>*/}
                  <Input
                    elementConfig={dataForm.mobileNo.elementConfig}
                    elementType={dataForm.mobileNo.elementType}
                    required={dataForm.mobileNo.validation.required}
                    isinvalid={!dataForm.mobileNo.valid}
                    isShowPasswordStrengthMeter={false}
                    invalidReason={dataForm.mobileNo.invalidReason}
                    touched={dataForm.mobileNo.touched}
                    value={dataForm.mobileNo.value}
                    isRightIcon={false}
                    rightIcon={""}
                    size={"lg"}
                    margin={"m-t-24"}
                    label={dataForm.mobileNo.label}
                    changed={(event) =>
                      this.inputHandleChange(event, dataForm.mobileNo.key)
                    }
                  />
                  <Input
                    elementConfig={dataForm.email.elementConfig}
                    elementType={dataForm.email.elementType}
                    required={dataForm.email.validation.required}
                    isinvalid={!dataForm.email.valid}
                    isShowPasswordStrengthMeter={false}
                    invalidReason={dataForm.email.invalidReason}
                    touched={dataForm.email.touched}
                    value={dataForm.email.value}
                    isRightIcon={false}
                    rightIcon={""}
                    size={"lg"}
                    margin={"m-t-24"}
                    label={dataForm.email.label}
                    changed={(event) =>
                      this.inputHandleChange(event, dataForm.email.key)
                    }
                  />
                  <Input
                    elementConfig={dataForm.message.elementConfig}
                    elementType={dataForm.message.elementType}
                    required={dataForm.message.validation.required}
                    isinvalid={!dataForm.message.valid}
                    touched={dataForm.message.touched}
                    value={dataForm.message.value}
                    size={"lg"}
                    margin={"m-t-24"}
                    label={dataForm.message.label}
                    changed={(event) =>
                      this.inputHandleChange(event, dataForm.message.key)
                    }
                  />
                </div>
                <div className={"mimc__br-action"}>
                  <Button
                    text={"Send Message"}
                    type={"dark md"}
                    size={"full-width"}
                    isLoading={isLoading}
                    leftIcon={false}
                    rightIcon={false}
                    reactIcon={""}
                    onClick={() => this.saveFeedback()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
