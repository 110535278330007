import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";
import {ButtonIcon} from "../../../components";

const Index = (props) => (
    <>
      {!props.isLoading && (
          <button
              className={`button-wrapper waves ${props.type} ${props.size} ${props.style}`}
              onClick={() => props.onClick()}
              onChange={() => props.onChange()}
              onKeyPress={props.onKeyPress}
          >
        <div className={`btn-inner-wrapper d-flex p-0 m-0`}>
          {props.leftIcon && (
            <div id={props.id} className={`left-icon-wrapper `}>
              <ButtonIcon
                type={props.type}
                buttonIcon={props.reactIcon}
                size={props.leftIconSize}
              />
            </div>
          )}
          <div className={"button-text-wrp p-0 m-0"}>{props.text}</div>

          {props.rightIcon && (
            <div className={`right-icon-wrapper`}>
              <ButtonIcon
                type={props.type}
                buttonIcon={props.reactIcon}
                size={props.size}
              />
            </div>
          )}
        </div>
      </button>
    )}

    {props.isLoading && (
      <button className={`button-wrapper disabled ${props.type} ${props.size}`}>
        <div className={`btn-inner-wrapper d-flex p-0 m-0`}>
          <div className={"button-text-wrp p-0 m-0"}>{props.text}</div>
        </div>
      </button>
    )}
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "unknown",
};

export default Index;
